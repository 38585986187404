.containerprofile {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
}

.formcontainerpf {
    /* box-sizing: border-box; */
    border: 0.1px solid #ddd;
    box-shadow: 0 4px 21px -12px rgba(0, 0, 0, .66);    
    width: 60%;
    height: 100%;
    border-radius: 1rem;
    padding: 2rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    /* display: flex;
    flex-wrap: wrap; */
}



.title{
    margin-top: 2rem;
}
.btnsave:link{
    background-color: #87CEFA;
}
.btnsave:hover{
    background-color: #87CEFA;
    color: #ffffff;
    border-color: #87CEFA;
}
.textbox{
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    text-decoration: none;
    color: rgb(72, 72, 72);
    border: 1px solid rgb(228, 228, 228);
    padding: 7px;
    border-radius: 8px;
}

.textbox a {
    margin-left: 10px;
  }
.textbox span {
    flex-grow: 1;
}

 .profile-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .profile-container input {
    flex: 1;
  }
  
  .profile-container a {
    margin-left: 10px;
    text-decoration: underline !important;;
    /* color: #87CEFA !important;;  */
  }
  
  .button-group {

    display: flex;
    justify-content: center; 
    gap: 10px; 
    margin-top: 10px; 
  }
  
  .custom-btn {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .custom-btn:hover {
    opacity: 0.9; 
  }
  
  .edit-btn {
    background-color: #ffc107; 
    color: #000000;

  }
  
  .edit-btn:hover {
    background-color: #e0a800; 
  }
  
.password-btn {
  background-color: #007bff; 
  color: white; /* สีน้ำเงินสำหรับ 'เปลี่ยนรหัสผ่าน' */
}

.password-btn:hover {
  background-color: #0056b3; /* สีน้ำเงินเข้มขึ้นเมื่อ hover */
}
  