/* @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai&display=swap');

* {
    font-family: 'IBM Plex Sans Thai', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} */

/* @import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;500&family=Noto+Sans+Thai+Looped&family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Trirong:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Prompt&display=swap');
/* * {
	font-family: "Prompt", serif;
} */
* {
	font-family: "Prompt", serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.logow {
    width: 15rem;

}

/* sidebar2 */
.body {
    position: relative;
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
}

.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 320px;
    /* background: #11101D; */
    background: #87CEFA;
    padding: 6px 10px;
    z-index: 99;
    transition: all 0.5s ease;
}

.sidebar.active{
    width: 82px;
}

.sidebar .logo_content .logo {
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    opacity: 1;
    pointer-events: none;
    transition: all 0.5s ease;
}
.sidebar.active .logo_content .logo {
    opacity: 0;
}

.sidebar #btn {
    position: absolute;
    color: rgb(0, 0, 0);
    left: 90%;
    top: 10px;
    font-size: 23px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    transform: translateX(-50%);
}
.sidebar.active #btn{
    left: 50%;
}

.sidebar ul {
    margin-top: 15px;
    padding-left: 0;
}

.sidebar ul li {
    position: relative;
    height: 60px;
    margin: 0 5px;
    width: 100%;
    list-style-type: none;
    line-height: 50px;
    font-size: 1rem;
    font-weight: bold; 
}
.sidebar.active .links_name{
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s ease;
}
.sidebar .links_name{
    opacity: 1;
    pointer-events: auto;
    transition: all 0.5s ease;
}
.sidebar ul li .tooltip{
    position: absolute;
    left: 122px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 6px;
    height: 35px;
    width: 250px;
    background: white;
    line-height: 35px;
    text-align: center;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.sidebar ul li:hover .tooltip{
    top: 50%;
    transition: all 0.5s ease;
    opacity: 1;
    pointer-events: none;
}

/* ค้นหา */
/* .sidebar ul li{ 
} */
.search-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 20px;  */
}
.search-text{
    border-radius: 5px;
    margin-top: 1rem;
    padding: .3rem;
    padding-left: .5rem;
    width: 16rem;
    border: 1.4px solid rgb(187, 186, 186) ;
}

/* .search-bar input {
    margin-left: 10px; 
} */

.sidebar ul li a {
    color: rgb(0, 0, 0);
    /* color: black; */
    display: flex;
    align-items: center;
    text-decoration: none;
    border-radius: 12px;
    white-space: nowrap;
    text-align: center;
    font-size: 1rem;
}

.sidebar ul li a:hover {
    width: 93%;
    color: black;
    background-color: white;
    transition: all 0.3s ease;
}

.sidebar ul li a i {
    height: 50px;
    min-width: 55px;
    border-radius: 12px;
    line-height: 50px;
    text-align: center;
    transition: all 0.3s ease;
}

.profile_details {
    font-size: 1rem;
    display: flex;
    align-items: center;
    margin-right: 30px; 
}

.profile_details li a{
    /* padding: 5px; */
    text-decoration: none;
    color: black;
    transition: all 0.5s ease;
}

.profile_details .nav-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
.profile_details .bi{
    margin-right: 15px;
}

.profile_details  li {
    cursor: pointer;
    list-style-type: none;
    margin-left: 10px;
}

.profile_details li a:hover {
    color: #4ab4ec;
}
.sidebar .nav-logout {
    position: absolute;
    color: white;
    bottom: 0;
    left: 88; /* Change this value to 0 */
    list-style-type: none;
    width: 93%;
}

.nav-logout li i {
    margin-top: 4px;
    min-width: 55px;
}
.sidebar .nav-logout li a:hover {
    color: black; 
    background-color: transparent; 
    transition: none; 
}



.profile #log_out{
    position: absolute;
    left: 50%;
    bottom: 5px;
    transform: translateX(-50%);
    min-width: 50px;
    line-height: 50px;
    font-size: 20px;
    border-radius: 12px;
    text-align: center;
    transition: all 0.5s ease;
}
.sidebar.active .profile #log_out {
    left: 88%;
}
.home_content {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    height: 100%;
    width: calc(100% - 320px); /* 320px คือความกว้างของ sidebar */
    left: 320px; /* ตำแหน่งเริ่มต้นของ home_content */
    transition: all 0.5s ease;
    margin-bottom: 2%;
  }
.profile_details .nav-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
.home_content .header{
    font-size:1.2rem;
    font-weight: 600;
    margin:0.3rem;
}
.header{
    padding: 10px;
}

/* หน้าลืมรหัสผ่าน */

.homereset_content{
    height: 100%;
    width: calc(100% - 78px);
    transition: all 0.5s ease;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
}

.homereset_content .logoreset{
    width: 45vh;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.homereset_content .header1{
    font-size: 25px;
    font-weight: 600;
    margin: 20px;
    text-align: center
    ;
}

.homereset_content .header2{
    font-size: 20px;
    font-weight: 600;
    margin: 20px;
    text-align: center;
}


/* หน้าลืมรหัสผ่าน */


.sidebar.active ~ .home_content{
    width: calc(100% - 78px);
    left: 78px;
}

.home_content .toolbar {
    /* float: right; */
    margin-right: 13%;
    line-height: 40px;
}
.toolbar {
    margin-top: auto; 
    align-self: end; 
    text-align: end;
  }
.home_content .toolbar .countadmin1{
    margin-top: 30px;
    text-align: center;
}
.bi-plus-circle {
    /* margin-right: 8px; */
  }
hr{
    margin: 2rem 2rem;
    color: inherit;
    background-color: gray;
    border: 1;
    opacity: .25;
}

.homeheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    border-bottom: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
.home_content h3{
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 30px;
    margin-top: 30px;
}
.home_content .breadcrumbs{
    margin-top: 21px;
    margin-left: 28px;
}
.breadcrumbs{
    padding: 10px;
    box-shadow: 0 4px 30px -10px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    width: fit-content;
    margin-top: 5px;
}
.breadcrumbs ul i{
    vertical-align: middle;
}
.breadcrumbs ul{
    display: flex;
    list-style-type: none;
    align-items: center;
    padding: 0;
    margin: 0;
    gap: 8px;
}
.breadcrumbs ul li a{
    text-decoration: none;
    color: black;
}
.breadcrumbs ul li a:hover{
    color: #87CEFA;
}
.breadcrumbs ul li .bihouse:hover{
    color: #87CEFA;
}
.breadcrumbs ul li:last-child a{
    font-weight: bold;
}

  