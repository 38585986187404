/* @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai&display=swap');

* {
    font-family: 'IBM Plex Sans Thai', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} */

/* @import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;500&family=Noto+Sans+Thai+Looped&family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Trirong:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Prompt&display=swap');
/* * {
	font-family: "Prompt", serif;
} */
.add:hover {
  background-color: #87CEFA;
  color: #ffffff;
  border-color: #87CEFA;
}

.deleteimg {
  border-radius: 5px;
  width: 5rem;
  background-color: rgb(236, 78, 78);
  color: white;
  border: none;
  margin-left: 10px; /* Space between username and button */
}

.deleteimg2 {
  border-radius: 5px;
  width: 5rem;
  background-color: rgb(236, 78, 78);
  margin: auto 1.6vh;
  color: white;
  border: none;
}
.editimg2 {
  border-radius: 5px;
  width: 5rem;
  background-color: #ffde59;
  color: black;
  border: none;
}


/* .detail {
  border-radius: 5px;
  width: 15vh;
  float: right;
  margin: auto auto;
  background-color: #007bff;
  color: white;
  border: none;
} */

/* .adminall .card-body .deleteimg {
  float: right;
} */



.adminall form {
  padding: 1.25rem;
}

.editimg {
  border-radius: 5px;
  width: 5rem;
  float: right;
  margin: auto auto;
  background-color: #ffde59;
  color: black;
  border: none;
}

.content {
  /* margin-top: 110px; */
  margin-bottom: 125px;
  
}

.content1 {
  margin-top: 100px;
}

.not-found-data {
  display: 'flex';
  text-align: center;
  color: red;
  font-size: 20px;
}

.form {
  align-items: center;
}

.btn {
  background-color: #87CEFA;
  color: #000000;
  text-align: center;
}

.card {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  /* line-height: 50px; */
  padding: 20px;
}

.filename a {
  text-decoration: none;
  color: rgb(72, 72, 72);
  border: 1px solid rgb(228, 228, 228);
  padding: 7px;
  border-radius: 8px;
}

.filename a:hover {
    background-color: #7e7e7e;
    color: white;
}

/* รูปที่แสดง */


.centered-image #previewImage {
  max-width: 30%;

}



.table-responsive {
  position: relative;
  /* height: 700px */
}


.action-icons img {
  margin-right: 5px;
  border-radius: 10px;
  /* ปรับระยะห่างระหว่างรูปภาพ */
  cursor: pointer;
  width: 5vh;
}




.gray-background {
  background-color: #e9ecef;
}

.editname {
  cursor: pointer;
  color: black;
}

.editname:hover {
  color: #87CEFA;
}

.d-grid {
  margin-top: 3%; /* ลดช่องว่าง */
  padding: 0;
}
.equipment-item {
  display: flex;
  align-items: center;
}

.equipment-item input {
  margin-right: 10px;
  margin-top: 8px;
}
.btn-group {
  gap: 10px;
  display: flex;
  justify-content: center;
  line-height: 2.2rem;
  /* margin-bottom: 30px; */
}

.btn-pre button {
  color: #000;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px 20px;
  cursor: pointer; 
  outline: none;
}

.btn-pre button:hover {
  /* background-color: #eee; */
  color:#000
}

.btn-next button {
  padding: 10px 30px;
}

.info {
  text-decoration: none;
  cursor: pointer;
}


.user-info {
  display: flex;
  justify-content: space-between;
  padding-left: 6%;

}

.right-info {
  margin-top: 21px;
  margin-left: 21px;
  word-spacing: 10px;
 flex: 1;
}
.left-info {
  margin-top: 12px;
  word-spacing: 12px;
  padding: 10px;
  margin-left: 21px;
}

.user-info p {
  line-height: 15px;
}

.action-icons {
  margin-top: 20px;
}

.deleteimg1 button {
  border-radius: 5px;
  width: 7rem;
  background-color: rgb(236, 78, 78);
  color: white;
  border: none;
  cursor: pointer;
}

.editimg1 button{
  border-radius: 5px;
  width: 7rem;
  background-color: #ffde59;
  color: black;
  border: none;
  cursor: pointer;

}

.adddata {
  margin-top: 8px; 
  display: flex;
  justify-content: center; 
  align-items: center;
}

.adddata button{
  border-radius: 5px;
  width: 7rem;
  background-color: #87CEFA;
  color: black;
  border: none;
}
.adddata button:hover {
  background-color: #5cb3fd; /* Darker blue on hover */
}
.delete-btn {
  color: rgb(255, 255, 255);
  background: rgb(236, 78, 78);
  border-radius: 3px;
  width: 10vh;
  padding: 15px;
  border: none;
  margin-left: 390px; /* ปรับตำแหน่งของปุ่มลบ */
}
.no-equipment {
   margin-bottom: 0%;
  margin-top: 19px;
  text-align: center;
}
.equip-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .adminall .card-body .deleteimg1 {
  float: none;
} */
.adminall .card-body {
  display: flex; /* Use flexbox */
  justify-content: space-between; /* Align items with space between */
  align-items: center; /* Center vertically */
  padding: 1rem;
}

.both-info {
  clear: both;
}

.required {
  color: red;
}

.countadmin {
  /* margin-right: 20px; */
}

/* .profile_details {
  margin-bottom: 20px;
} */

/* .breadcrumbs {
  margin-bottom: 20px;
}

.breadcrumbs ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.breadcrumbs ul li {
  display: inline-block;
  font-size: 14px;
  margin-right: 5px;
  color: #666;
}

.breadcrumbs ul li i {
  margin-right: 5px;
}

.breadcrumbs ul li.arrow {
  margin: 0 5px;
} */

.adminall {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  line-height: 2.5rem;
  padding: 1rem;
  border-radius: 10px;
  min-height: 6rem;
}

/* .adminall {
  flex: 1 1 10%;
  min-height: 120px;
  display: flex; 
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  line-height: 50px;
  padding: 20px;
} */

.adminall label {
  font-weight: 500;
}

.adminall input[type="text"],
.adminall input[type="file"] {
  width: 100%;
  /* padding: 10px; */
  border: 1px solid #ccc;
  border-radius: 5px;
  /* margin-bottom: 10px; */
}

.adminall .centered-image {
  text-align: center;
}

.adminall .filename {
  /* margin-bottom: 10px; */
}

.adminall .pdf {
  /* margin-top: 5px; */
}

.errormessage {
  color: red;
	font-size:1em;
}

.table100{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
th,
td {
  /* width: 27%; */
}

.table {
  width:75%; 
  margin :0 auto;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1); 
  line-height: 50px; 
  padding: 15px; 
  border-collapse: collapse; 
  border-spacing: 1; 
  border-radius: 10px; 
  position: relative;
  overflow: hidden;
}

.table .buttongroup-in-table button {
  /* line-height: normal; */
  line-height: 2.5rem;
}

.table th, .table td {
  padding: 10px;
  padding-left: 20px;
  /* text-align: left; */
  border-bottom: 1px solid #dee2e6;
}

.table th {
  font-size: 1rem;
  /* font-weight: bold; */
  background-color: #87CEFA;
  color: #000;
  text-transform: uppercase;
  border-bottom: 2px solid #87CEFA; 
}

.table td:last-child {
  width: 20%; 
  white-space: nowrap; 
}
.info3 .header {
  background-color: #95d7ff;
  color: #000; 
  padding: 5px 0px 5px 25px;
  margin: 0;
  border-radius: 3px 3px 0 0;
}
.info3 .header b{
  font-size: 20px;
}
.info3.card {
  padding: 0;
}
.info3 {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  line-height: 50px;
}



.info {
  color: #007bff;
  cursor: pointer;
  text-decoration: none;
  
}


.info:hover {
  color: #0056b3;
}

.equipname{
  margin-top: 30px;
  margin-left: 50px;
  line-height: 10px;
}
.success{
  text-align: center;
  padding-left: 0 !important;
}

/* th.success {
  text-align: center;
} */
.centered-cell {
  text-align: center;      /* Center horizontally */
  vertical-align: middle;  /* Center vertically */
}

.btn.btn-save {
  justify-content: center; 
  align-items: center;   
}
.verify {
  margin-left: auto;
  text-decoration: none; 
  color: #007bff; 
  cursor: pointer;
}
.form-control {
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  position: relative; 
}
.error-messageotp {
  display: inline-block; 
  margin: 0;
}

.newotp {
  display: inline-block; 
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}
/* .label-container {
  display: flex;
  align-items: center; 
}

.label-inline {
  margin-right: 10px;
}
h5 {
  margin: 0;
} */
.message-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.card-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttongroup {
  display: flex;             
  justify-content: flex-end;  
  gap: 10px;      
  /* line-height: 50px; */
          
}
.card-title{
  margin: 0;
  font-size: 1rem; 
}

/* ปุมลบไฟล์ */
.delete-button-file {
  background-color: transparent;
  border: none;
  color: #dc3545;
  font-size: 20px; 
  cursor: pointer; 
  transition: color 0.3s; 
}
.delete-button-image {
  background-color: transparent;
  border: none;
  color: #dc3545;
  font-size: 20px; 
  cursor: pointer; 
  transition: color 0.3s; 
}
.delete-button-file:hover {
  color: #c82333; 
}

.delete-button-file:focus {
  outline: none;
}
.btn-danger {
  background-color: #ff4d4f;
  border-color: #ff4d4f;
  color: #fff;
  transition: background-color 0.3s ease;
}

.btn-danger:hover {
  background-color: #ff6666; 
  border-color: #ff6666;
}

.centered-image {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  align-items: center;

}

.centered-image img {
  max-width: 100%;
  max-height: 300px;
  border-radius: 8px;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  object-fit: cover;
}

.filename {
  /* margin-top: 10px; */
  font-size: 16px;
}

.filename a {
  color: #007bff; 
  text-decoration: none;
  transition: color 0.3s ease;
}

.filename a:hover {
  color: #0056b3;
}

.bi-filetype-pdf {
  margin-right: 5px; 
  font-size: 20px; 
}

.info-alert {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  height: 2rem;
  color: #6c757d;
}

.no-results {
  text-align: center;
  color: gray;
  font-size: 1rem;
  margin-top: 20px;
}

  /* background-color: #f9f9f9;  */
  /* background-color: #ffc107; 
  color: #555;
  font-size: 14px;
  padding: .1rem 0; */
  /* padding: 10px 15px;  */
  /* border-left: 5px solid #ffc107; */
  /* border-radius: 5px;
  text-align: center;  */
@media (max-width: 768px) {
  .card-body {
    flex-direction: column; 
    align-items: flex-start;
  }
.buttongroup{
  margin-top: 10px;
}
  .deleteimg {
    /* margin-top: 10px;  */
    /* width: 100%;  */
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
}


.no-data {
  color: gray;
  font-style: italic;
  background-color: #fff; /* สีพื้นหลัง */
  padding: 15px;
}


.user-info-caregiver {
  padding-left: 7%;
}
.caregiver-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.caregiver-card {
  display: flex; /* จัดการ layout ให้เป็น flexbox */
  justify-content: space-between; 
  /* align-items: flex-start;  */
  gap: 20px;
  line-height: normal;
  max-width: 80%; /* หรือค่าที่เหมาะสม */
  width: 100%; /* ให้ขยายเต็มเฉพาะ container */
  margin: 10px ; /* จัดให้อยู่กึ่งกลาง */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* .caregiver-card {
  flex: 1 1 calc(20% - 1rem);
  box-sizing: border-box; 
  width: 30em; 
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  padding: 10px;
  margin: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
} */

.caregiver-card p {
  margin: 5px 0;
}

/* .caregiver-card b {
  color: #007bff;
} */

.inline-container {
  margin-left: 21px;
  display: flex;
  flex-wrap: wrap; 
  gap: 1rem;
  justify-content: flex-start;
}

.button-container-vertical {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  gap: 10px; 
  line-height: 2.2rem;
  justify-content: center;
}

.button-edit {
  background-color: #ffde59; /* สีเขียว */
  color: rgb(0, 0, 0);
  border: none;
  /* padding: 10px 20px; */
  border-radius: 5px;
  cursor: pointer;
  width: 100px; /* กำหนดขนาดความกว้าง */
  text-align: center;
}

.button-delete {
  background-color: rgb(236, 78, 78); /* สีแดง */
  color: white;
  border: none;
  /* padding: 10px 20px; */
  border-radius: 5px;
  cursor: pointer;
  width: 100px;
  text-align: center;
}
/* 
.button-edit:hover {
  background-color: #45a049;
}

.button-delete:hover {
  background-color: #d32f2f;
} */

.relationship-group {
  display: flex;
  flex-wrap: wrap; /* ทำให้ย่อขนาดเมื่อพื้นที่ไม่พอ */
  gap: 15px; /* ระยะห่างระหว่างตัวเลือก */
  /* margin-top: 10px; */
  margin-left: 2%;
}

input[type="radio"] {
  margin-right: 5px;
}
