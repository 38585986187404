/* @import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;500&family=Noto+Sans+Thai+Looped&family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Trirong:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Prompt&display=swap');
/* * {
	font-family: "Prompt", serif;
} */
* {
	font-family: "Prompt", serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
/* @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai&display=swap');

* {
	font-family: 'IBM Plex Sans Thai', sans-serif;
} */

.bartop {
	margin-top: 60px;
}

.logincontainer {
	width: 100%;
	min-height: 100vh;
	background-color: #f5f5f5;
	display: flex;
	align-items: center;
	justify-content: center;
}

.formcontainer {
	width: 900px;
	height: 500px;
	display: flex;
	border-radius: 10px;
}

.left {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #87CEFA;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.left h1 {
	color: white;
}

.right {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: white;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}

.btnlogin {
	background-color: #87CEFA;
	color: white;
}

.logologin {
	width: 40vh;
	align-items: center;
	margin-bottom: 1.5vh;
}

.forgot-password{
	text-align: right;
}
.forgot-password a{
	font-size: 2vh;
    text-decoration: none;
    color: rgb(72, 72, 72);
    padding: 7px;
    border-radius: 8px;
}

.form-control {
	font-size: 2vh;
}

/* #errormessage {
	color: red;
	font-size:1.5vh;
} */

/* .form_container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.form_container h1 {
	font-size: 40px;
	margin-top: 0;
} */
.box {
	display: flex;
}

/* New style for the equipment table */
.equipment-table {
    width: 100%; /* Full width of the container */
    border-collapse: collapse; /* Collapse borders */
}

.equipment-table th, .equipment-table td {
    padding: 2px 55px; /* More padding for better spacing */
    text-align: left; /* Align text to the left */
    border-bottom: 1px solid #dee2e6; /* Bottom border */
}

.equipment-table th {
    background-color: #f1f1f1; 
    color: hsl(0, 0%, 0%); /* Header text color */
    text-transform: uppercase; /* Uppercase text for header */
}

/* .equipment-table tr:nth-child(even) {
    background-color: #f9f9f9; 
} */

.equipment-table tr:nth-child(odd) {
    background-color: #ffffff; /* White for odd rows */
}

.equipment-table td input[type="checkbox"] {
    margin-right: 10px; /* Margin for checkboxes */
}

.equipment-category h4 {
	font: bold;
    margin-left: 25px; /* Add margin to separate from the table */
    color: #333; /* Color of category headers */
    font-size: 1.2em;
    text-transform: uppercase;
}
.equipment-table th:nth-child(1), .equipment-table td:nth-child(1) {
	margin-left: 50px;
    width: 18%; /* Width for checkbox column */
}
.equipment-table th:nth-child(2), .equipment-table td:nth-child(2) {
    width: 5%; /* Width for checkbox column */
}

.equipment-table th:nth-child(3), .equipment-table td:nth-child(3) {
    width: 30%; /* Width for equipment name column */
}

.equipment-table th:nth-child(4), .equipment-table td:nth-child(4) {
    width: 45%; /* Width for date added column */
}

