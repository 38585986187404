.header-saveimg{
  font-size: 1.4em;
  font-weight: bold;
  text-align: center;
  margin: 1% 0;
}
.save-img {
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  border: 2px solid #87CEFA;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
  margin-top: 2rem;
}
.data-container {
  flex: 1;
  padding: 10px;
  margin-left: 10%;
  /* background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}
  
  .data-container {
    flex: 1;
  }
  .user-data-container {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
}
  
.qrcode-container {
  display: flex;
  /* justify-content: space-around; */
justify-content: center;
  align-items: center;
  gap: 20px; 
  /* padding: 20px; */
  flex-wrap: wrap; /* ช่วยให้รูปเลื่อนลงถ้าไม่พอกับหน้าจอ */
}

.qrcode-item {
  text-align: center;
}
.qrcode-item {
  flex: 1;
  text-align: center;
  margin: 10px;
  max-width: 200px; /* จำกัดความกว้างของแต่ละ QR Code */
}
.qrcode {
  width: 100%; /* ทำให้รูป QR Code เต็มพื้นที่ของ .qrcode-item */
  height: auto;
}

.image-description {
  font-size: 14px;
  /* margin-top: 10px; */
}

.button-container {
  justify-content: center;
  display: flex;
  gap: 15px;
  /* margin-top: 20px; */
}
.label-saveimg {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  color: #333;
}

.label-name {
  font-weight: bold;
  /* margin-left: 4rem; */
  color: #333;
}
.label-saveimg-scan {
  display: flex;
  justify-content: center;
}
.label-scan {
  font-weight: bold;
  color: #ff4500;
  text-align: center;

}
label-scan-save{
  text-align: center;
}
  .user-data {
    margin: 0;
    padding: 0;
    line-height: inherit; 
  }

  .btn-manage-user, .btn-save-image {
    width: 15rem;
    margin-right: 10px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
 .btn-manage-user{
  background-color: #fff;
  border: 2px solid #87CEFA;
  text-align: center;
    margin-top: 20px;
  }

  .btn-save-image{
    background-color: #87CEFA;
    color: #fff;    
    text-align: center;
    margin-top: 20px;
    border: none;
  }

  .btn-manage-user:hover {
    background-color: #87CEFA;
    color: #fff;
}

.btn-save-image:hover {
    background-color: #00BFFF;
}
  